<template>
  <header class="header">
    <div class="header__top">
      <div class="header__top__logo">
        <img src="../assets/logo.png" alt="biodp logo">
      </div>
      <div class="header__top__search">
        <span>BioDP</span>
        <!--
        <div class="header__top__search__container">
          <input type="text" class="input" placeholder="Waar ben je naar op zoek?">
          <button class="svgbutton"><fa icon="search"></fa></button>
        </div> -->
      </div>
      <div class="header__top__controls">
        <!-- <button class="svgbutton"><fa icon="user"></fa></button> -->
        <!-- <button class="svgbutton"><fa icon="shopping-cart"></fa></button> -->
        <a class="svgbutton" href="tel:+32472940227"><fa icon="phone-volume"></fa></a>
        <a class="svgbutton" href="mailto:contact@biodp.eu"><fa icon="envelope"></fa></a>
      </div>
    </div>
    <div class="header__bottom">
      <div class="header__bottom__nav">
        <router-link to="/" exact>Home</router-link>
        <!-- <router-link to="/products">Producten</router-link> -->
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
name: "TheHeader"
}
</script>