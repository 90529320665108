import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
//import Product from "@/views/Product";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //{
  //  path: '/products',
  //  name: 'Products',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  //},
  //{
  //  path: '/product/:id',
  //  name:'Product',
  //  component: Product
  //},
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/:notFound(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
